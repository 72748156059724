module.exports= {
    language: {
        name: 'CN',
        wap_name: 'CN'

    },
    //头部导航
    header: {
        text1: '首页',
        text2: '产品案例',
        text3: '关于我们',
        text4: '新闻资讯',
        text5: '加入我们',
        text6: '联系我们',
    },

    //底部导航
    footer: {
        type1: '官网：https://www.zhongtianhuiyu.com',
        type2: '邮箱：ztianhuiyu@163.com',
        type3: '电话：0451-82715555',
        type4: '服务时间：工作日：9：00-17：30',
        type5: '地址：黑龙江省哈尔滨市松北区松北大道36号',
        jpg_text1: '微信公众号',
        jpg_text3: '小红书',
        jpg_text5: '抖音',
        btm_text1: '黑ICP备2021011899号-1',
    },

    //首页
    home: {
        mouse1: '请用鼠标滚轮',
        mouse2: '控制罗盘',
        h1: '哈尔滨中天汇宇投资集团有限公司成立于2021年3月，集团总部坐落于冰城哈尔滨，是集研发、制造、贸易、投资为一体的综合企业集团。中天汇宇集团深耕综合投资领域，布局互联网研发、科技娱乐、宠物服务、美妆护肤、餐饮管理、传媒营销六大投资领域。经过多年深度实践，已形成以环宇互娱、沐知其生物科技、高美妍商贸、靖峰科技、食锁餐饮、彩世文化传媒等公司为核心的品牌产业矩阵，目前集团在海内外已投出多家前沿领域的高质量项目。',
        h2: '中天汇宇高速发展，十余家产业子公司相继成立',
        h3: '2023年-2024年，集团战略版图拓展至长三角、 珠三角地区。哈尔滨哈奇马宠物服务有限公司、 杭州靖峰科技有限公司、杭州思语信息软件技 术有限公司、杭州哈奇马宠物服务有限公司、 广州高美妍生物科技有限公司、哈尔滨七炭河 餐饮管理有限公司深圳分公司，相继成立。',
        
        j1: '企业愿景',
        j2: '企业价值观',
        j3: '企业使命',

        j11: `构建一切皆有价值的投资世界<br>
        让投资创造财富狂潮<br>
        成为全球卓越的投资企业`,
        j22: ``,
        j33: ``,

        his:[
            '',
            '01月',
            '02月',
            '03月',
            '04月',
            '05月',
            '06月',
            '07月',
            '08月',
            '09月',
            '10月',
            '11月',
            '12月',
        ]
    },

    //关于我们
    about_us: {
        top_title1: '企业愿景',
        top_dec1: '技精于专，做于细；业成于勤，守于挚',
        top_title2: '企业使命',
        top_dec2: '攻守并重，全员实动，活动目标，服务导向',
        top_title3: '企业价值观',
        top_dec3: '匠心不泯，创新先行，博观而约取 厚积而薄发',
        company_text: '哈尔滨中天汇宇投资集团有限公司（以下简称“集团”）于2021年3月成立，总部位于美丽的哈尔滨冰城。作为一家综合企业集团，集团集制造、研发、贸易、投资于一体，涉及互联网、现代服务、宠物综合服务、美妆护肤、餐饮管理、科技娱乐等实业投资领域。集团秉承“诚信载道，开拓创新，科技建兴，行稳致远”的经营理念，并以“打造中国最具责任感的投资服务商”为愿景，致力于培育发展资源优势产业、壮大公司本体、创造价值、服务社会、成就员工的光荣使命。未来，集团将坚持“集团运营—产业投资—金融服务—资源整合”的发展模式，致力于成为实力雄厚、资产优良、管理规范、效益良好、影响力大的现代化企业集团。集团将全力打造科技建设、实体运营服务、金融投资管理为一体的实体化投资公司。',
        btm_text1: '服务热线',
        btm_text2: '哈尔滨中天汇宇投资集团有限公司，专注于科技产业及应用领域投资，拥有 高素质的专业化团队，科学的管理制度，坚持与坚韧明达的创业团队共同成长。',
        btm_text3: '地址：黑龙江省哈尔滨市松北区松北大道36号',
        btm_text4: '服务时间：工作日：9：00-17：30',
        btm_text5: '邮箱：zhongtianhuiyu@163.com',
        tab1: '企业简介',
        tab2: '企业文化',

        h2: '哈尔滨中天汇宇国际投资集团有限公司成立于2021年3月，深耕互联网研发、科技娱乐、宠物服务、美妆护肤、餐饮管理、传媒营销六大投资领域。经过多年深度实践，已形成以环宇互娱、沐知其生物科技、高美妍商贸、靖峰科技、食锁餐饮、彩世文化传媒等公司为核心的投资产业矩阵。2023年，集团业务领域拓展至长三角、珠三角地区，战略版图逐步覆盖全国；2024年，中天汇宇产业布局将放眼全球，海外首家子公司将于美国成立，持续打造全球化产业联盟。',
        h3: '哈尔滨中天汇宇国际投资集团有限公司，专注于科技产业',
        h4: '及应用领域投资，拥有高素质的专业化团队，科学的管理制度，',
        h5: '坚持与坚韧明达的创业团队共同成长。',
    },

    //新闻资讯
    newsList: {
        tab1: '公司新闻',
        tab2: '行业新闻',
    },


    //新闻详情
    newsDetail: {
        time: '时间',
        back: '返回列表'
    },


    //联系我们
    contactUs: {
        title: '在高德地图上找到我们',
        item_text1: '邮箱',
        item_tip1: 'zhongtianhuiyu@163.com',
        item_text2: '地址',
        item_tip2: '黑龙江省哈尔滨市松北区松北大道36号',
        item_text3: '服务时间',
        item_tip3: '工作日：9：00-17：30',
        item_text4: '电话',
        item_tip4: '0451-82715555',
        placeholder1: '您的姓名',
        placeholder2: '电话号码',
        placeholder3: '你想说的话',
        submit: '提交留言',

        h1: '哈尔滨中天汇宇国际投资集团',
        h2: '如有意向前往哈尔滨中天汇宇国际投资集团有限公司，请提前一天预约，欢迎您的到来。',
        h3: '电话'
    },

}