<template>
    <div class="footter" onselectstart="return false" :style="background? background: o_background">
        <div class="footer_conb dis_between">

            <img src="@/assets/footer/footer_logo.png" class="footer_logo"/>


            <div class="dis">
                <div style="margin-right: 40px;">
                    <div class="btm_text" style="font-size: 20px; letter-spacing: 3px;">中天汇宇国际投资集团</div>
                    <div class="btm_text" style="font-size: 14px; letter-spacing: 2px; opacity: .54; margin-top: 2px;">ZOOM TEN INTERNATIONAL INC.</div>

                    <div class="btm_text" style="font-size: 13px; letter-spacing: 1px; opacity: .48; margin-top: 26px;">{{ $t('footer.type1') }}</div>
                    <div class="btm_text" style="font-size: 13px; letter-spacing: 1px; opacity: .48; margin-top: 10px;">{{ $t('footer.type2') }}</div>
                    <div class="dis" style="margin-top: 10px; width: 400px; flex-wrap: wrap;">
                        <div class="btm_text" style="font-size: 13px; letter-spacing: 1px; opacity: .48;  margin-right: 22px;">{{ $t('footer.type3') }}</div>
                        <div class="btm_text" style="font-size: 13px; opacity: .48;">{{ $t('footer.type4') }}</div>
                    </div>
                    <div class="btm_text" style="font-size: 13px; letter-spacing: 1px; opacity: .48; margin-top: 10px; width: 400px;">{{ $t('footer.type5') }}</div>
                </div>

                <div class="dis">
                    <div class="dis_column" style="transform: translateY(10px); margin-right: 10px;">
                        <img src="@/assets/footer/smg1.png" class="smg" />
                        <div class="smg_text">{{ $t('footer.jpg_text1') }}</div>
                    </div>
                  <div class="dis_column" style="transform: translateY(10px); margin-right: 10px;">
                    <img src="@/assets/footer/smg5.png" class="smg" />
                    <div class="smg_text">{{ $t('footer.jpg_text5') }}</div>
                  </div>
                    <div class="dis_column" style="transform: translateY(10px); margin-right: 10px;">
                        <img src="@/assets/footer/smg3.png" class="smg" />
                        <div class="smg_text">{{ $t('footer.jpg_text3') }}</div>
                    </div>
                </div>
                
            </div>

        </div>
        <div class="dis_center" style="width: 100%; height: 50px;background-color: #1C1E2B;">
            <div style="color: #fff; opacity: .3; font-size: 12px; margin-right: 68px; letter-spacing: 1px;">©中天汇宇国际投资集团</div>
            <a href="https://beian.miit.gov.cn" target="_blank" class="ip">{{ $t('footer.btm_text1') }}</a>
        </div>
    </div>
</template>


<script>

    export default{
        data(){
            return{
                tab_list: [
                    { title: 'footer.nav1' },
                    { title: 'footer.nav2' },
                    { title: 'footer.nav3' },
                    { title: 'footer.nav4' }
                ],
                tab_item: [
                    [
                        { title: 'footer.col1_text1' },
                        { title: 'footer.col1_text2' },
                    ],
                    [
                        { title: 'footer.col2_text1' },
                        { title: 'footer.col2_text2' },
                    ],
                    [
                        { title: 'footer.col3_text1' },
                        { title: 'footer.col3_text2' },
                    ],
                    [
                        { title: 'footer.col4_text1' },
                        { title: 'footer.col4_text2' },
                    ]
                ],
                o_background: 'background-color: #292c3d;'
            }
        },
        props:['background'],
    }

</script>


<style scoped>
    .footter{
        width: 100%;
        height: 310px;
        position: relative;
        z-index: 1;
        min-width: 1200px;

    }
    .footer_conb{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        padding: 80px 0 0 0;
        box-sizing: border-box;
        align-items: flex-start;
    }

    .footer_logo{
        width: 140px;
        /* height: 47px; */
        transform: translateY(30px);
    }

    
    .list_tab{
        width: 100%;
        height: 48px;
        border-bottom: 1px solid #007ffb;
        padding-left: 55px;
        box-sizing: border-box;
    }
    .tab_item{
        width: 128px;
        font-size: 18px;
        color: #329EFC;
        font-weight: bold;
        /* text-shadow: 0px 2px 2px rgba(3,42,75,0.1); */
        font-family: Segoe UI-Bold, Segoe UI;
    }
    .tab_items{
        width: 128px;
        font-size: 14px;
        color: #07184D;
        font-weight: 400;
        /* text-shadow: 0px 2px 2px rgba(3,42,75,0.1); */
        font-family: SimHei-Regular, SimHei;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .tab_items_text{
        margin: 4.5px 0;
    }
    .ip{
        font-size: 12px;
        /* font-family: DengXian-Bold, DengXian; */
        color: #fff;
        letter-spacing: 1px;
        text-decoration: unset;
        opacity: .3;
        /* text-shadow: 0px 2px 2px rgba(0,0,0,0.1); */
    }


    .smg{
        width: 100px;
        height: 100px;
    }
    .smg_text{
        font-size: 12px;
        color: #FFFFFF;
        opacity: .5;
        margin-top: 10px;
        text-align: center;
    }
    @media screen and (max-width: 1300px) {
        .footer_conb {
            zoom: .9;
        }
    }


    .btm_text{
        color: #CED4EA; 
        opacity: .84;

    }
</style>